@import url("https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap");

$height: 60px;

* {
  box-sizing: border-box;
  font-size: 24px;
}

html,
body {
  font-family: "Rubik Mono One", sans-serif;
  margin: 0;
  padding: 0;
  max-width: 100vw;
  min-width: 100vw;
  bottom: 0;
  top: 0;
  background: #34e89e;
  background: -webkit-linear-gradient(to right, #0f3443, #34e89e);
  background: linear-gradient(to right, #0f3443, #34e89e);
  overflow-y: hidden;
  position: fixed;
  z-index: -2;
}

a {
  text-decoration: none;
  color: #000000;
}

#root {
  padding: 3vw;
}

.shine {
  height: 300px;
  position: fixed;
  top: -100px;
  left: -100px;
  height: 1080px;
  width: 1080px;
  transform-origin: top left;
  opacity: 0.4;
  transition: transform 0.1s;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;

  &.shine2 {
    left: auto;
    right: -100px;
    transform-origin: top right;
    transition: transform 0.2s;
  }
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: -100px;
  bottom: -100px;
  width: calc(100% + 100px);
  height: calc(100% + 100px);
  z-index: -1;
  filter: grayscale(1);
}

form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 3vw;
  left: 3vw;
  right: 3vw;

  > div {
    width: 100%;
    max-width: 798px;
    display: flex;

    #elmo {
      width: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top center;
      position: absolute;
      bottom: 200px;
      height: 0px;
      transition: height 0.3s;
    }

    input[type="checkbox"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
      width: calc($height - 4px);
      min-width: calc($height - 4px);
      height: calc($height - 4px);
      min-height: calc($height - 4px);
      margin: 2px 0 0 10px;
      position: relative;
      padding: 0;
      border-radius: 0;
      &:after {
        content: "+1";
        font-size: 30px;
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 4px solid #000000;
        padding: 0;
        border-radius: 0;
      }

      &:checked {
        &:after {
          color: #ffffff;
          background-color: #000000;
        }
      }
    }
  }

  input[type="text"] {
    width: 100%;
    max-width: 600px;
    height: $height;
    padding: 0 20px;
    border: 4px solid #000000;
    margin: 0 0 10px 0;
  }

  label {
    transform: translate(0px, -3px);
    display: inline-block;
    user-select: none;
  }

  button[type="submit"] {
    width: 100%;
    max-width: 250px;
    height: $height;
    background-color: #000000;
    border: none;
    padding: 0 20px;
    color: #ffffff;
    cursor: pointer;
  }
}

h1 {
  font-size: 70px;
  line-height: 70px;
  margin: 0;

  .o-break {
    display: none;
  }
}

h2 {
  font-size: 30px;
  line-height: 30px;
  margin: 20px 0 20px 0;
  opacity: 0.7;
}

.Countdown {
  position: absolute;
  bottom: 0;
  left: 0;
}

.btn-start {
  position: absolute;
  top: 3vw;
  right: 3vw;
  width: $height;
  height: $height;
  border: 4px solid #000000;
  background-color: #ffffff;
  cursor: pointer;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
  svg {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 60px;
    line-height: 60px;

    .o-break {
      display: block;
    }
  }
  .btn-start {
    position: relative;
    right: unset !important;
  }
  form {
    button[type="submit"] {
      max-width: 100%;
    }
  }
}
